<template>
  <router-view/>
  <ModalsContainer />
</template>

<script>
import { ModalsContainer } from 'vue-final-modal'
export default {
  name: 'APP',
  components: {
    ModalsContainer
  }
}
</script>

<style>
@import './assets/css/master.css';
@import './assets/css/icons.css';
@import './assets/css/typography.css';
</style>
 