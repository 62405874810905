import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import { createVfm } from 'vue-final-modal'



import CardBox from '@/components/components/cards/CardBox.vue'
import LoadingState from '@/components/LoadingState.vue'

import 'vue-final-modal/style.css'

// import naive from 'naive-ui'
// app.use(naive)

const app = createApp(App)
app.component('card-box', CardBox)
app.component('loading-state', LoadingState)

const vfm = createVfm()

app.use(vfm)
app.use(router)
app.mount('#app')

