import { createRouter, createWebHistory } from 'vue-router'

import auth from '@/middleware/auth'

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../pages/LoginPage.vue'),
    meta: {
      middleware: auth
    }
  },
  {
    path: '/',
    name: 'main',
    redirect: 'dashboard',
    component: () => import(/* webpackChunkName: "main" */ '../layouts/AdminLayout.vue'),
    meta: {
      middleware: auth
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../pages/DashboardPage.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'dashboard', action: 'list' }
        },
      },

      // ARTICLES ROUTES
      {
        path: '/articles',
        name: 'Articles',
        component: () => import(/* webpackChunkName: "articles-list" */ '../pages/articles/ArticlesList.vue'),
      },
      {
        path: '/articles/:id',
        name: 'Article #{id}',
        component: () => import(/* webpackChunkName: "article-view" */ '../pages/articles/ArticleView.vue'),
      },
      // ARTICLES ROUTES

      // TAGS ROUTES
      {
        path: '/tags',
        name: 'Tags',
        component: () => import(/* webpackChunkName: "tags-list" */ '../pages/tags/TagsList.vue'),
      }
      // TAGS ROUTES


    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return () => {
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  };
}
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }
  return next()
})

export default router
