export default async function auth({ to, next, router}) {
  const token = localStorage.getItem('alem-admin-token')
  if (token) {
    if (to.name === 'auth') {
      return router.push({ name: 'main'})  
    }
  } else if (to.name !== 'auth' && !token) {
    return router.push({ name: 'auth' })
  }
  return next()
}
